import './App.css';
import Form from './component/Form'
import { BrowserRouter as Router, Route } from "react-router-dom";

import imgaaa from './img/img1.png'
function App() {


  
  return (
    <div className="App">   
      <Router > 

      <a href="https://zaban.co.il/">
          <img width="300" src={imgaaa} alt="Zaban Jewelry" style={{ marginTop: 50 }} />
      </a>
      <header className="App-header">




        <h1 style={{ marginBottom: 20, marginTop: 20}}>
    הרשמה למועדון הלקוחות 
        </h1>
          <Route path="/" exact component={() => <Form />} />
  
      </header>
      </Router>
    </div>
  );
}

export default App;
