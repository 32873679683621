import React,{useState} from 'react'
import {Form,Button,Alert,Col,Row,Container } from 'react-bootstrap';
// import webservice from '../config';

function Forma() {

const maxe =  new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
 const [alertflag, setAlertflag] = useState(false);
  const [gender, setGender] = useState('');

  const liveurl = "/nodejs/send" 
  

   const ShowSuccesMsg = () => {
    setAlertflag(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAlertflag(false);
    }, 6000);
    
  };

   const onPost = (e) => {
         e.preventDefault();
ShowSuccesMsg();

            // alert ( document.getElementById("formBasicEmail").value)
                async function testPost() {

      let response = await fetch(`${liveurl}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          phone: document.getElementById("formBasicPhone").value,
          email: document.getElementById("formBasicEmail").value,
          bdate: document.getElementById("formBasicDate").value,
          check: document.getElementById("formBasicCheckbox").checked,
          name: document.getElementById("formBasicName").value,
          lname: document.getElementById("formBasicLName").value,
          gender: gender

        }),
      });
        let t = await response.json();
      if 
        (t.Success === true){   
        document.getElementById("wms").reset();
       
            
 
      }  


    }
    testPost();
  }




    return (
        <Container style={{textAlign: "right"}} >
               <Alert  transition={false} show={alertflag}  variant="success"  style={{ marginTop: 20,textAlign: "center" }}>
        הנתונים הוזנו בהצלחה
      </Alert>
        <Form onSubmit={onPost} id="wm
        s">

<Row>
  <Form.Group as={Col}  controlId="formBasicName">
    <Form.Label >שם פרטי</Form.Label>
    <Form.Control  type="text" name="fname" />

  </Form.Group>


   <Form.Group as={Col} controlId="formBasicLName">
    <Form.Label >שם משפחה</Form.Label>
    <Form.Control    type="text"  name="lname"  />

  </Form.Group>

 
   </Row>
   

   <Row>

      <Form.Group   as={Col} controlId="formBasicDate" 
     style={{width: "50%"}}>
    <Form.Label>תאריך לידה</Form.Label>
    <Form.Control 

    required type="date"  min="1910-12-31" max ={maxe} />

  </Form.Group>

            
  <Form.Group   as={Col} controlId="formBasicPhone">
    <Form.Label>טלפון סלולרי</Form.Label>
    <Form.Control required type="phone"    pattern="[0-9]{10}" />

  </Form.Group>

</Row>


    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label >דואר אלקטרוני</Form.Label>
    <Form.Control   type="email"   style={{textAlign: "left"}}/>

  </Form.Group>


    <Form.Group className="mb-3" controlId="formBasiGender" required>
    <Form.Label >מין</Form.Label>

  {[ 'radio'].map((type) => (
    <div key={`default-${type}`} className="mb-3">
       <Form.Check
        inline
        label="זכר"
        name="group1"
        type={type}
        id={`inline-${type}-1`}
        onFocus= {() => setGender('ז')}
      />
     <Form.Check
        inline
        label="נקבה"
        name="group1"
        type={type}
        id={`inline-${type}-2`}
                onFocus= {() => setGender('נ')}

      />
  
    </div>
  ))}

    </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicCheckboxConfirm">
  <Form.Check type="checkbox" label= {<label>מאשר/ת <a href='https://zaban.co.il/members-terms/'>תקנון</a></label>} required/>

                        


  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicCheckbox">
    <Form.Check type="checkbox" label="מאשר/ת קבלת חומר פרסומי" defaultChecked = "true" />

  </Form.Group>


<div className="d-grid gap-2">
  <Button variant="warning" type="submit" block
//    disabled ={alertflag}
     >
    אישור
  </Button>

</div>
 

</Form>

        </Container >
    )
}

export default Forma
